<template>
  <div v-if="allProductIds !== null && allProductIds.length > 0">
    <div class="tab-con btn btn-danger m-0" @click="displayModal">
      <div class="fs-4 font-mobile"><i class="fas fa-shopping-basket"></i></div>
    </div>

    <div v-if="showModal" class="modal-overlay" @click="showModal = false">
      <div class="modal-content" @click.stop>
        <h5 class="text-white mb-3">{{ $t('orderHold.title') }}</h5>
        <div class="pending-list-container">
          <ul v-if="allProductIds.length > 0" class="pending-list">
            <li
              v-for="item in allProductIds"
              :key="item._id"
              class="pending-item"
            >
              <div class="order-no">{{ item.game_id.name }}</div>
              <div class="description">{{ item.name }}</div>
              <div class="game-name">{{ item.price }} {{ $t('coinTopup.bath') }}</div>
            </li>
          </ul>
          <p v-else class="text-white text-center mt-4">{{ $t('orderHold.notHold') }}</p>
        </div>
        <button @click="showModal = false" class="close-btn">{{ $t('close') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showModal: false,
      incompleteOrder: null,
      allProductIds: null,
    };
  },
  created() {
      this.getProfile();
      this.fetchOrders();
  },
  computed: {
    ...mapGetters(['orders', 'getMemberProfile']),
  },
  watch: {
    orders: {
      handler: 'filterPending',
      deep: true
    }
  },
  methods: {
    ...mapActions(['fetchOrders', 'getProfile']),
    displayModal(){
        this.showModal = true
        // console.log('this.orders: ', this.orders);
        // console.log('filterPending: ', this.filterPending());
    },
    filterPending() {
        // สร้าง array เพื่อเก็บ product_id ทั้งหมด
        let allProductIds = [];
        
        let responseData = this.orders.filter((order) => order.status_order == 1 ||order.status_order == 2);
        responseData.forEach(order => {
            let orderProductArray = order.order_product_id_arr;
            orderProductArray.forEach(product => {
                // Push product_id ลงใน array
                allProductIds.push(product.product_id);
            });
        });        
        // คุณสามารถใช้ allProductIds ใน template ของคุณได้ตามต้องการ
        this.allProductIds = allProductIds;
    },
  }
};
</script>

<style scoped>
.tab-con {
  position: fixed;
  bottom: 100px;
  right: 15px;
  background-color: #000000;
  border: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px 18px;
  z-index: 9999;
}

.tab-con:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.modal-content {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin-top: 7%;
}

.pending-list-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 15px;
}

.pending-list {
  list-style-type: none;
  padding: 0;
}

.pending-item {
  margin-bottom: 15px;
  padding: 12px;
  background-color: #454545;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.pending-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #7a7a7aad;
  cursor: pointer;
}

.order-no {
  font-weight: bold;
  color: #e00b60;
  margin-bottom: 3px;
}

.game-name {
  font-size: 1em;
  color: #ffffff;
  margin-bottom: 3px;
}

.description {
  color: #cccccc;
  font-size: 0.9em;
}

.close-btn {
  align-self: center;
  padding: 8px 20px;
  background-color: #e00b60;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.close-btn:hover {
  background-color: #ff1a75;
}

/* ปรับแต่ง scrollbar */
.pending-list-container::-webkit-scrollbar {
  width: 6px;
}

.pending-list-container::-webkit-scrollbar-track {
  background: #454545;
}

.pending-list-container::-webkit-scrollbar-thumb {
  background-color: #e00b60;
  border-radius: 20px;
}

@media (max-width: 500px) {
    .modal-content {
        margin-top: 25%;
    }
    .close-btn{
        width: 100%;
    }
}
@media (max-width: 768px) {
  .tab-con {
    padding: 10px 13px;
  }
  .font-mobile{
    font-size: 18px !important;
  }
}
</style>
