<template>
    <div class="row position-relative w-100 m-auto">
        <div class="col-12 col-md-12 px-0">
            <div class="row">
                <div class="col px-0 main-slider m-auto">
                    <Carousel ref="myCarousel" v-model="currentSlide" v-bind="settings" :breakpoints="breakpoints">
                        <Slide v-for="(slide, index) in getBanners" :key="index">
                            <div class="slide-con">
                                <a @click.prevent="navigateToGame(slide.url)" target="_blank" rel="noopener noreferrer" style="width: 100%;">
                                    <div class="carousel__item" :style="`background-image: url(${slide.img_url})`"></div>
                                    <!-- <div class="carousel__item"></div> -->
                                </a>                        
                            </div>
                        </Slide>                  
                        <template #addons>
                            <Pagination/>
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  import { Carousel, Pagination, Slide } from 'vue3-carousel'
  
  import 'vue3-carousel/dist/carousel.css'
  
  export default defineComponent({
    name: 'WrapAround',
    components: {
      Carousel,
      Slide,
      Pagination
    },
    props: {
        banners: {
            type: Array,
            default: () => [],
        }
    },
    data () {
        return {
            sliders: [
                {
                    img_url: '',
                },
                {
                    img_url: '',
                },
                {
                    img_url: '',
                },
                {
                    img_url: '',
                },
            ],
            currentSlide: 0,
            currentSlideBg: 1,
            currentSlideBgTwo: 1,
            breakSlide: 0,
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
                autoplay: 5000
            },
            breakpoints: {
                1024: {
                    itemsToShow: 2.15,
                    snapAlign: 'center',
                },
                991: {
                    itemsToShow: 1.5,
                    snapAlign: 'center',
                },
                767: {
                    itemsToShow: 1.25,
                    snapAlign: 'center',
                },
                575: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
            },
        }
    },
    // mounted() {
    //     console.log("listBannner",this.banners);
    // },
    computed: {
        getBanners() {
            return this.banners && this.banners.length >= 4 ? this.banners : this.sliders
        },
        nextOneSlide() {
            if (this.currentSlide == this.sliders.length - 1) {
                return 0
            }
            return this.currentSlide + 1
        },  
        nextTwoSlide() {
            if (this.currentSlide == this.sliders.length - 2) {
                return 0
            }
            if (this.currentSlide == this.sliders.length - 1) {
                return 1
            }
            return this.currentSlide + 2
        }  
    },
    methods: {
        navigateToGame(nameGame) {
            if (nameGame.includes('pubg')) {
            this.$router.push('/game-topup/666986a5156b4e640521c2b0');
            }
            else if(nameGame.includes('valorant')){
            this.$router.push('/game-topup/6669830a156b4e640521c214');

            }else if(nameGame.includes('arenabreakout')){
            this.$router.push('/game-topup/666a8f26a1ff42ef68d26b0d');
            }
            // ถ้าไม่มี กลับค่าเป็นสตริงว่าง เพื่อไม่ให้มีลิงก์
            return '';
        }
    },
    watch: {
        currentSlide: function(newValue, oldValue) {
            if (newValue >= oldValue) {
                if (newValue <= this.sliders.length) {
                    this.currentSlideBg = oldValue + 1
                    this.currentSlideBgTwo = oldValue + 2
                } else {
                    this.currentSlideBg = newValue
                    this.currentSlideBgTwo = newValue
                }
            } else {
                if (newValue <= this.sliders.length) {
                    this.currentSlideBg = oldValue + 1
                    this.currentSlideBgTwo = oldValue + 2
                } else {
                    this.currentSlideBg = newValue
                    this.currentSlideBgTwo = newValue
                }
            }
        }
    }
  })
  </script>
  
  
  <style lang="scss" scoped>
  .main-slider {
    .carousel {
    height: auto;
    position: unset !important;

    &__slide {
      padding: 0;
    }

    &__viewport {
      width: 100vw; 
    }

    &__track {
      display: flex;
      align-items: center;
    }
  }
}
  .carousel {
    margin: 10px 0 !important;
    .carousel__pagination{
        position: relative !important;
    }
    .carousel__pagination-button {
        &::after {
            transition: width .3s ease-in-out, background-color .3s ease-in-out;
            background-color: #fff;
        }
        &.carousel__pagination-button--active {
            &::after {
                width: 30px;
                background-color: #ff5800;
            }
        }
    }
  }
  .carousel-fade {
    margin: 0 !important;
    .carousel__track {
        transition: none !important;
    }

    .carousel__slide {
        padding: 10px;
    }

    &.-l-10 {
        .carousel__slide {
            padding-left: 0;
        }
    }
    &.-r-10 {
        .carousel__slide {
            padding-right: 0;
            padding-bottom: 5px;
            padding-top: 5px;
        }
    }
    
    &.-in {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        .carousel__slide {
            opacity: 0;
            transition: opacity .5s ease-in-out !important; /* Adjusted duration and timing function */
            &.carousel__slide--active {
                opacity: 1;
                transition: opacity .5s ease-in-out !important; /* Adjusted duration and timing function */
            }
        }
    }
  }
  .carousel__item {
    // max-height: 400px;
    // background-image: url("https://plus.unsplash.com/premium_photo-1718204436526-277f9f34607c?q=80&w=3009&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") !important;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color: var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    // aspect-ratio: 15/6;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 350px; 
    margin: 0 ;
  }

  @media only screen and (max-width: 900px) {
    .carousel__slide {
        padding: 0;
    }
  }
  
  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 2px solid white;
    color: #fff;
  }
  .slide-con{
    width: 100%;
    margin: 0;
  }
  .position-relative{
    width: 90% !important;
  }

  @media only screen and (max-width: 580px) {
    .slide-con {
        width: 90%;
    }
    .carousel__item{
        min-height: 250px; 

    }
  }
  
  </style>
  