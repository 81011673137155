<template>
  <navbar :is-blur="true" :dark-mode="true" />
  
  <div class="container-fluid px-0 main my-8">
    <div class="j-space my-3"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col">
          <h1 class="text-center text-white mb-4">ยืนยันหมายเลขโทรศัพท์</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
          <div class="card z-index-1 blur-dark-fade">
            <div class="card-body">
              <div class="mb-3">
                <input
                  v-if="!isConfirmPhone"
                  id="phone"
                  class="form-control"
                  type="tel"
                  placeholder="หมายเลขโทรศัพท์"
                  name="verifyphone"
                  v-model="phoneNumber"
                  :disabled="isShowReCaptcha"
                />
              </div>
              <div class="mb-3">
                <input
                  v-if="isConfirmPhone"
                  id="otpcode"
                  class="form-control"
                  name="otpcode"
                  type="otpcode"
                  placeholder="กรอกรหัส OTP ที่ได้รับ"
                  v-model="otpCode"
                />
              </div>

              <div v-if="!isConfirmPhone" id="recaptcha-container"></div>
              
              <div class="text-center">
                <soft-button
                  v-if="!isShowReCaptcha && !isShowReCaptcha"
                  class="my-4 mb-2 text-lg"
                  color="danger"
                  full-width
                  @click="requestOTP()"
                  >ยืนยัน หมายเลขโทรศัพท์
                </soft-button>
              </div>

              <div class="text-center">
                <soft-button
                  class="my-4 mb-2 text-lg"
                  color="danger"
                  full-width
                  v-if="isConfirmPhone"
                  :disabled="!otpCode"
                  @click="confirmOTP()"
                  >ยืนยัน OTP
                </soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";

import { store, mapMutations, mapGetters } from "vuex";
import axios from 'axios';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

export default {
  name: "SigninBasicOTP",
  components: {
    Navbar,
    AppFooter,
    SoftButton,

  },
  data() {
    return {
      otpCode: '',
      phoneNumber: '',
      remember_login: false,
      isConfirmPhone: false,
      isShowReCaptcha: false,
      verify: window.localStorage.getItem('verify') || false,
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    this.hideModal();

    setTimeout(() => {
      if (!this.isLoggedIn) {
        return this.$router.push('/signin');
      }
      
      if (this.getMemberProfile && this.getMemberProfile.verdify_mobile) {
        // console.log(this.getMemberProfile)
        return this.$router.push('/');
      }
    }, 300);
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getMemberProfile']),
    getPhoneNumber() {
      return this.replaceZeroWithCountryCode(this.phoneNumber);
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    replaceZeroWithCountryCode(phoneNumber) {
      if (phoneNumber.startsWith('0')) {
        return '+66' + phoneNumber.slice(1);
      } else {
        return phoneNumber;
      }
    },
    hideModal() {
      document.querySelector("body").style = "";
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        document.querySelector(".modal-backdrop").remove();
      }
    },
    requestOTP() {
      this.isShowReCaptcha = true;
      if (!this.getPhoneNumber) {
        return this.$swal({
          icon: "warning",
          title: 'กรุณากรอกเบอร์โทรศัพท์',
          type: 'warning',
        });
      }

      const auth = getAuth();
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
      
      signInWithPhoneNumber(auth, this.getPhoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          this.isConfirmPhone = true;
          this.confirmationResult = confirmationResult; // Save confirmationResult for later use
        })
        .catch((error) => {
          console.error('Error requesting OTP:', error);
          this.$swal({
            icon: "error",
            title: 'ผิดพลาด กรุณาลองใหม่',
            type: 'error',
          });
          // window.location.reload();
        });
    },
    confirmOTP() {
      const code = this.otpCode; // Assuming you have a data property for otpCode

      if (!code) {
        return this.$swal({
          icon: "warning",
          title: 'กรุณากรอกรหัส OTP',
          type: 'warning',
        });
      }

      this.confirmationResult.confirm(code)
        .then((userCredential) => {
          const user = userCredential.user;
          const profile = this.getMemberProfile;
          profile.mobile = this.phoneNumber;
          profile.verdify_mobile = true;
          window.localStorage.setItem('verify', this.phoneNumber);
          this.$store.dispatch('updateProfile', profile);
          this.$swal({
            icon: "success",
            title: 'ยืนยันหมายเลขโทรศัพท์สำเร็จ',
            type: 'success',
          });
          setTimeout(() => {
            return this.$router.push('/');
          }, 500);
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: 'รหัส OTP ไม่ถูกต้อง',
            type: 'error',
          });
        });
    },
  },
};
</script>


<style scoped lang="scss">
  .form-check-label {
    margin-bottom: 0;
  }
</style>