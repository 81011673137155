<template>
  <navbar :is-blur="true" :dark-mode="true" />
  
  <!-- <div class="page-header relative">
    <div class="container-fluid px-0 mt-7">
      <MainSlider />
    </div>
  </div> -->

  <div class="container-fluid px-0 main my-8">
    <div class="j-space my-3"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col">
          <h1 class="text-center text-white mb-4">{{ $t('signIn.signin')}}</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
          <div class="card z-index-1 blur-dark-fade">
            <div class="card-body">
              <form role="form" class="text-start" @submit.prevent="login">
                <div class="mb-3">
                  <input
                    id="phone"
                    class="form-control"
                    type="text"
                    :placeholder="$t('signIn.username')"
                    name="username"
                    v-model="user.username"
                  />
                </div>
                <div class="mb-3">
                  <input
                    id="password"
                    class="form-control"
                    name="password"
                    type="password"
                    :placeholder="$t('signIn.password')"
                    v-model="user.password"
                  />
                </div>
                <div class="d-flex justify-content-between">
                  <div class="form-check form-switch">
                    <input
                      id="rememberMe"
                      class="form-check-input"
                      type="checkbox"
                      name="name"
                      v-model="remember_login"
                    />
                    <label class="form-check-label" for="rememberMe">
                      {{ $t('signIn.remember')}}
                    </label>
                  </div>
                  <!-- <a href="#" class="text-white"><i>ลืมรหัสผ่าน</i></a> -->
                </div>
                <div v-if="getSetting.menu_tem" class="text-center">
                  <soft-button
                    class="my-4 mb-2 text-lg"
                    color="danger"
                    full-width
                    >{{ $t('signIn.login')}}
                  </soft-button>
                </div>
                <div class="d-flex justify-content-between">
                  <a role="button" @click="$router.push('/remember-pass')" class="text-right text-white py-3">{{ $t('signIn.rePassword')}}</a>
                  <a href="/signup" class="text-white py-3">{{ $t('signIn.signup')}}</a>
                </div>
                <!-- <div class="mb-2 text-center position-relative">
                  <p
                    class="px-3 mb-2 text-sm bg-white font-weight-bold text-secondary text-border d-inline z-index-2"
                  >
                    หรือ
                  </p>
                </div> -->
                <div v-if="getSetting.menu_tem" class="text-center">
                  <button
                    class="btn mt-2 mb-2 text-lg btn-outline-danger w-100"
                    type="button"
                    @click="redirectToLineLogin"
                    ><i class="fab fa-line"></i> {{ $t('signIn.loginLine')}}
                  </button>
                  <button
                    class="btn mt-2 mb-2 text-lg btn-outline-danger w-100"
                    type="button"
                    @click="redirectToGoogleLogin"
                    ><i class="fab fa-google"></i> {{ $t('signIn.loginGoogle')}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
      id="jkanban-info-modal"
      class="modal fade"
      style="display: none"
      tabindex="-1"
      role="dialog"
    >
    <div class="modal-dialog">
      <div class="modal-content blur-dark-fade">
        <div class="modal-header">
          <h5 class="h5 modal-title text-white">{{ $t('signIn.rePassword2')}}</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="pt-4 modal-body text-white">
          <a
            class="btn mt-2 mb-2 text-lg btn-outline-danger w-100"
            role="button"
            href="https://line.me/R/ti/p/@710oclzg?ts=06201834&oat_content=url"
            target="_blank"
            ><i class="fab fa-line"></i> @goldenbug
          </a>
          <a
            class="btn mt-2 mb-2 text-lg btn-outline-danger w-100"
            role="button"
            href="https://www.facebook.com/GoldenbugGameShopping"
            target="_blank"
            ><i class="fab fa-facebook"></i> GoldenBug
          </a>
          <div class="text-end">
            <button
              class="m-1 btn btn-secondary"
              @click.prevent="hideModal"
            >
              {{ $t('close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="jkanban-info-modal-backdrop"
    class="fixed inset-0 z-40 hidden bg-black opacity-50"
  ></div>

  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
// import MainSlider from "@/views/pages/components/MainSlider.vue";

import { mapMutations, mapGetters } from "vuex";
import axios from 'axios';
export default {
  name: "SigninBasic",
  components: {
    Navbar,
    AppFooter,
    SoftButton,
    // MainSlider
  },
  data() {
    return {
      user: {
        username: '',
        password: '',
      },
      remember_login: false
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push('/profile');
    }
    const jcr_user = window.localStorage.getItem('jcr_user');
    const jcr_pwd = window.localStorage.getItem('jcr_pwd');

    if (jcr_user && jcr_pwd) {
      this.user.username = jcr_user;
      this.user.password = jcr_pwd;
      this.remember_login = true;
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getSetting']),
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async showModal() {
      const bootstrap = this.$store.state.defaultStore.bootstrap;

      let jkanbanInfoModal = document.getElementById(
        "jkanban-info-modal"
      );

      var myModal = new bootstrap.Modal(jkanbanInfoModal, {
        show: true
      });
      myModal.show();
    },
    hideModal() {
      document
            .querySelector("#jkanban-info-modal")
            .classList.remove("show");
          document.querySelector("body").classList.remove("modal-open");
          document.querySelector("body").style = "";
          document.querySelector(".modal-backdrop").remove();
    },
    redirectToLineLogin() {
      const clientId = '2004893434'; // Replace with your LINE Channel ID
      const redirectUri = encodeURIComponent('https://termgame.goldenbug.co/auth/line');
      const state = 'someRandomState'; // This should be randomly generated for security reasons
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=profile`;
    },
    redirectToGoogleLogin() {
      const clientId = '1055649956934-4cqqoel3sa26m2p7kpghcqhpedfvkp1m.apps.googleusercontent.com'; 
      const redirectUri = encodeURIComponent('https://termgame.goldenbug.co/auth/google');
      window.location.href = `https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/userinfo.email&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
    },
    login() {
      let data = new URLSearchParams();
      // console.log(this.user)
      data.append('username', this.user.username);
      data.append('password', this.user.password);

      if (this.remember_login) {
        window.localStorage.setItem('jcr_user', this.user.username)
        window.localStorage.setItem('jcr_pwd', this.user.password)
      } else {
        window.localStorage.removeItem('jcr_user')
        window.localStorage.removeItem('jcr_pwd')
      }

      axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
      axios.post(`${this.$store.state.defaultStore.host_api}/auth/login`, data)
        .then(response => {
          if (!response.data.success) {
            return this.$swal({
                icon: "warning",
                title: this.$t('remember.warningTitle'),
                text: this.$t('remember.warningText'),
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: this.$t('remember.cancelButtonText'),
                confirmButtonText: this.$t('remember.confirmButtonText'),
                customClass: {
                  confirmButton: "btn bg-danger fs-6 p-3 rounded",
                  cancelButton: "btn bg-dark fs-6 p-3 rounded",
        },
            }).then((result) => {
                if (result.isConfirmed) {
                } else {
                  // localStorage.setItem('username', JSON.stringify(this.user.username));
                  this.$router.push("/remember-pass");
                }
              });
          }
          // Handle the response data using a function
          const TOKEN = response.data.datas.tokenData ?? false;
          if (!TOKEN) {
            console.log('error');
            return false;
          }
          this.$store.dispatch('setProfile', response.data.datas);
          this.$store.dispatch('loginSuccess', TOKEN.token);
          window.localStorage.setItem('jcr_token', TOKEN.token)

          const selectedItem = JSON.parse(window.localStorage.getItem('selectedItem'));
          if (selectedItem && selectedItem.length) {
            const game_id = selectedItem[0].game_id._id;
            return window.location.href = `/game-topup/${game_id}`;
          }
          return window.location.href = `/`;
          // window.location.href = `/${this.getSetting.member_landing ? this.getSetting.member_landing : ''}`;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
};
</script>


<style scoped lang="scss">
  .form-check-label {
    margin-bottom: 0;
  }
</style>