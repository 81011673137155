<template>
    <div>
      Processing...
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        userProfile: null
      };
    },
    created() {
      const code = this.$route.query.code;
      if (code) {
        this.handleLoginCallback(code);
      }
    },
    methods: {
      async handleLoginCallback(code) {
        const accessToken = await this.getAccessToken(code);
        if (accessToken) {
          this.userProfile = await this.getUserProfile(accessToken);
        }
      },
      async getAccessToken(code) {
        const data = {
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: 'https://termgame.goldenbug.co/auth/line',
          client_id: '2000349635',
          client_secret: 'f85046498ebb38c8d07e9658aad826ed'
        };
  
        try {
          const response = await axios.post('https://api.line.me/oauth2/v2.1/token', data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          return response.data.access_token;
        } catch (error) {
          console.error('Error obtaining access token:', error);
        }
      },
      async getUserProfile(accessToken) {
        try {
          const response = await axios.get('https://api.line.me/v2/profile', {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          });
          // console.log(response.data);
          return response.data;
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    }
  };
  </script>  