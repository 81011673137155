<template>
  <navbar :is-blur="true" :dark-mode="true" />
  
  <div class="container mt-6">
    <div class="row">
      <div class="col-lg-7 text-center mx-auto mt-6">
        <h1 class="text-white text-xl">เงื่อนไขการให้บริการ</h1>
      </div>
    </div>
  </div>

  <div class="container pt-5 mb-5">
    <div class="row">
      <div class="col-12 text-white" v-html="getSetting?.page?.termservice?.text">
      </div>
    </div>
  </div>
  <div class="j-space my-3"></div>
  
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
// import SoftInput from "@/components/SoftInput.vue";
// import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftButton from "@/components/SoftButton.vue";

import { mapMutations, mapGetters } from "vuex";
import AOS from "aos";
import 'aos/dist/aos.css';
import Rellax from 'rellax';

export default {
  name: "SigninBasic",
  components: {
    Navbar,
    AppFooter,
    // SoftInput,
    // SoftSwitch,
    // SoftButton,
  },

  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    AOS.init();
    if (document.getElementsByClassName('rellax')) {
      new Rellax('.rellax', {
        speed: 2,
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: true
      });

    //   rellax.destroy();
    }
  },
  computed: {
    ...mapGetters(['getSetting'])
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },
};
</script>

<style scoped lang="scss">
    // .container {
    //     max-width: 960px;
    // }
</style>