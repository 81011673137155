import axios from 'axios';
// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://termgame-api.goldenbug.co/api";


export default {
  state() {
    return {
      banks: [],
      bank: null
    }
  },
  mutations: {
    setBanks(state, banks) {
      state.banks = banks;
    },
    setBank(state, bank) {
      state.bank = bank;
    },
    addBank(state, bank) {
      state.banks.push(bank);
    },
    updateBank(state, updatedBank) {
      const index = state.banks.findIndex(bank => bank._id === updatedBank._id);
      if (index !== -1) {
        state.banks[index] = updatedBank;
      }
    },
    removeBank(state, bankId) {
      state.banks = state.banks.filter(bank => bank._id !== bankId);
    }
  },
  actions: {
    async fetchBanks({ commit }) {
      try {
        const response = await axios.post(`${API_URL}/bank/getList`);
        commit('setBanks', response.data.datas);
      } catch (error) {
        console.log('An error occurred while fetching banks: ', error);
      }
    },
    async fetchBankById({ commit }, bankId) {
      try {
        const response = await axios.get(`${API_URL}/banks/${bankId}`);
        commit('setBank', response.data);
      } catch (error) {
        console.log('An error occurred while fetching the bank: ', error);
      }
    },
    async createBank({ commit }, bankData) {
      try {
        const response = await axios.post(`${API_URL}/banks`, bankData);
        commit('addBank', response.data);
      } catch (error) {
        console.log('An error occurred while creating the bank: ', error);
      }
    },
    async updateBank({ commit }, bankData) {
      try {
        const response = await axios.put(`${API_URL}/banks/${bankData._id}`, bankData);
        commit('updateBank', response.data);
      } catch (error) {
        console.log('An error occurred while updating the bank: ', error);
      }
    },
    async deleteBank({ commit }, bankId) {
      try {
        await axios.delete(`${API_URL}/banks/${bankId}`);
        commit('removeBank', bankId);
      } catch (error) {
        console.log('An error occurred while deleting the bank: ', error);
      }
    }
  },
  getters: {
    banks(state) {
      return state.banks.filter(x => x.url_img);
    },
    bank(state) {
      return state.bank;
    }
  }
}