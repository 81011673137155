<template>
  <navbar :is-blur="true" :dark-mode="true" />
      
  <div class="container mt-6">
    <div class="row">
      <div class="col-lg-7 text-center mx-auto mt-6">
        <h1 class="text-white text-xl">{{ $t('sideNav.historyTopup')}}</h1>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <!-- <nav-pill /> -->
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-3">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <div class="card blur-dark-fade mt-4">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table align-items-center mb-0 text-white">
                <thead>
                  <tr>
                    <th>{{ $t('coinTopup.ref')}}</th>
                    <th>{{ $t('coinTopup.tablePay')}}</th>
                    <th>{{ $t('coinTopup.timePay')}}</th>
                    <th>{{ $t('coinTopup.remark')}}</th>
                    <th class="text-center">{{ $t('coinTopup.status')}}</th>
                  </tr>
                </thead>
                <tbody v-if="reversedTopupTransaction && reversedTopupTransaction.length">
                  <tr v-for="(item, index) in reversedTopupTransaction" :key="index">
                    <td>{{ item._id }}</td>
                    <td>{{ this.$filters.toCurrency(item.creadit_total_topup) }}</td>
                    <td>{{ moment(item.created_date_iso).format('DD-MM-YYYY HH:mm:ss') }}</td>
                    <td>{{ item.remark_admin }}</td>
                    
                    <td class="text-center" v-html="checkTopupStatus(item.status_topup_credit)"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>
  
  <script>
  import Navbar from "@/examples/PageLayout/Navbar.vue";
  import SideNav from "@/views/pages/Account/components/SideNav.vue";
  import AppFooter from "@/examples/PageLayout/Footer.vue";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  
  export default {
    name: "MemberProfile",
    components: {
      Navbar,
      SideNav,
      AppFooter
    },
    data() {
      return {
        moment: moment
      }
    },
    created() {
      this.getHistoryTopupCredit();
      this.checkLogin();
    },
    computed: {
      ...mapGetters(['games', 'gameBanner', 'getSetting', 'banks', 'getMemberProfile', 'topupTransaction', 'getMemberProfile']),
      reversedTopupTransaction() {
        return this.topupTransaction ? this.topupTransaction.slice().reverse() : [];
      }
    },
    methods: {
      ...mapActions(['fetchBanks', 'getHistoryTopupCredit']),
      async checkLogin() {
        const checkLogin = typeof this.getMemberProfile !== 'undefined';
        if (!checkLogin) {
          await this.$store.dispatch('logOut');
          return this.$router.push('/signin');
        }
      },
      checkTopupStatus(status) {
        let badge, text;
        if (status == 0) {
          badge = 'danger';
          text = this.$t('historyOrder.pending');
        }
        if (status == 1) {
          badge = 'info';
          text = this.$t('historyOrder.inProcess');
        }
        if (status == 2) {
          badge = 'success';
          text = this.$t('historyOrder.success');
        }
        if (status == 9) {
          badge = 'error';
          text = this.$t('historyOrder.error');
        }

        return `<label class="badge badge-${badge} m-0">${text}</label>`;
      },
    }
  };
  </script>