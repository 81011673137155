import axios from 'axios';
// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://termgame-api.goldenbug.co/api";

export default {
  state() {
    return {
      games: [],
      gamesTopup:[],
      game: null
    }
  },
  mutations: {
    setGames(state, games) {
      state.games = games;
    },
    setGame(state, game) {
      state.game = game;
    },
    addGame(state, game) {
      state.games.push(game);
    },
    setGamesTopup(state,game){
      state.gamesTopup = game;
    },
    updateGame(state, updatedGame) {
      const index = state.games.findIndex(game => game._id === updatedGame._id);
      if (index !== -1) {
        state.games[index] = updatedGame;
      }
    },
    removeGame(state, gameId) {
      state.games = state.games.filter(game => game._id !== gameId);
    }
  },
  actions: {
    
    async fetchGames({ commit }) {
      try {
        const response = await axios.post(`${API_URL}/game/getList`);
        let games = response.data.datas;
        commit('setGames', games);
      } catch (error) {
        console.log('An error occurred while fetching games: ', error);
      }
    },
    async fetchGameById({ commit }, gameId) {
      try {
        const response = await axios.post(`${API_URL}/game/getList`, JSON.stringify({ game_id: gameId }),
        { headers: { 'Content-Type': 'application/json' } });
        commit('setGame', response.data.datas);
      } catch (error) {
        console.log('An error occurred while fetching the game: ', error);
      }
    },
    async createGame({ commit }, gameData) {
      try {
        const response = await axios.post(`${API_URL}/games`, gameData);
        commit('addGame', response.data);
      } catch (error) {
        console.log('An error occurred while creating the game: ', error);
      }
    },
    async updateGame({ commit }, gameData) {
      try {
        const response = await axios.put(`${API_URL}/games/${gameData._id}`, gameData);
        commit('updateGame', response.data);
      } catch (error) {
        console.log('An error occurred while updating the game: ', error);
      }
    },
    async deleteGame({ commit }, gameId) {
      try {
        await axios.delete(`${API_URL}/games/${gameId}`);
        commit('removeGame', gameId);
      } catch (error) {
        console.log('An error occurred while deleting the game: ', error);
      }
    }
  },
  getters: {
    games(state) {
      return state.games;
    },
    gamesTopupAuto(state) {
      return state.games.filter(x => x.topup_auto);
    },
    gamesTopupNormal(state) {
      return state.games.filter(x => !x.topup_auto);
    },
    game(state) {
      return state.game;
    },
    gamesTopup(state) {
      return state.gamesTopup;
    }
  }
}