<template>
  <footer class="py-3 footer">
    <div class="container">
      <ul class="footer-nav d-flex gap-3 justify-content-center flex-wrap">
        <li>
          <router-link
          to="/game-topup"
            class="cursor-pointer"
          >
          {{ $t('homepage.topupGame') }} 
          </router-link>
        </li>
        <li>
          <router-link
          to="/coin-topup"
            class="cursor-pointer"
          >
          {{ $t('homepage.topupCoin') }} 
          </router-link>
        </li>
        <li>
          <router-link
          to="/policy"
            class="cursor-pointer"
          >
          {{ $t('homepage.policy') }} 
          </router-link>
        </li>
        <li>
          <router-link
          to="/terms-of-services"
            class="cursor-pointer"
          >
          {{ $t('homepage.condition') }} 
          </router-link>
        </li>
      </ul>
      <p class="mb-0 text-secondary text-center">
        COPYRIGHT @ {{ new Date().getFullYear() }} GOLDENBUG DEVELOPMENT CO., LTD.., ALL RIGHTS RESERVED.
      </p>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AppFooter",

  components: {
    
  },
  created() {
    // this.getSettingCMS();
  },
  computed: {
    ...mapGetters(['getSetting']),
  },
  methods: {
    ...mapActions(['getSettingCMS'])
  }
};
</script>

<style scoped lang="scss">
  footer {
    &.footer {
      background-color: #000; 
    }
  }
  .footer-nav {
    list-style-type: none;
    padding-left: 0;
    a {
      font-size: 1rem;
      color: #fff;
      transition: all .3s ease-in-out;
      &:hover {
        color: #FF4E00;
      }
    }
  }

  @media (max-width: 768px) {
    .footer-nav li a{ 
      font-size: 14px !important;
    }
    .text-secondary{
      font-size: 12px !important;
    }
  }
</style>