export function initFbPixel() {
    /* Meta Pixel Code */
    !function(f, b, e, v, n, t, s) {
      if (f.fbq) return; n = f.fbq = function() {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n;
      n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)
    }(window, document, 'script',
      'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1161864581702882');
    fbq('track', 'PageView');
    /* End Meta Pixel Code */
}

export function initTiktokAnalytics() {
    !function(w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = w[t] = w[t] || [];
        ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"];
        ttq.setAndDefer = function(t, e) { t[e] = function() { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } };
        for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
        ttq.instance = function(t) { for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]); return e };
        ttq.load = function(e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            ttq._i = ttq._i || {};
            ttq._i[e] = [];
            ttq._i[e]._u = i;
            ttq._t = ttq._t || {};
            ttq._t[e] = +new Date;
            ttq._o = ttq._o || {};
            ttq._o[e] = n || {};
            var o = document.createElement("script");
            o.type = "text/javascript";
            o.async = !0;
            o.src = i + "?sdkid=" + e + "&lib=" + t;
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(o, a)
        };

        ttq.load('CPPVQ53C77U75I0QPF8G');
        ttq.page();

        // Add identify code before event code
        ttq.identify({
            "email": "<hashed_email_address>", // ต้องแฮชด้วย SHA-256
            "phone_number": "<hashed_phone_number>", // ต้องแฮชด้วย SHA-256
            "external_id": "<hashed_extenal_id>" // ต้องแฮชด้วย SHA-256
        });

        // Track CompleteRegistration event
        ttq.track('CompleteRegistration', {
            "contents": [
                {
                    "content_id": "<content_identifier>", // เช่น "1077218"
                    "content_type": "<content_type>", // เช่น "product"
                    "content_name": "<content_name>" // เช่น "shirt"
                }
            ],
            "value": "<content_value>", // เช่น 100
            "currency": "<content_currency>" // เช่น "USD"
        });

        // Track CompletePayment event
        ttq.track('CompletePayment', {
            "contents": [
                {
                    "content_id": "<content_identifier>", // เช่น "1077218"
                    "content_type": "<content_type>", // เช่น "product"
                    "content_name": "<content_name>" // เช่น "shirt"
                }
            ],
            "value": "<content_value>", // เช่น 100
            "currency": "<content_currency>" // เช่น "USD"
        });

    }(window, document, 'ttq');
}

export function initGoogleTag() {
    // Google tag (gtag.js)
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11252626125";
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        window.gtag = gtag;

        gtag('js', new Date());
        gtag('config', 'AW-11252626125');
    };
}

export function initGoogleTagNew() {
    // Google tag (gtag.js)
    const script = document.createElement('script');
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-8BZBG47672";
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        window.gtag = gtag;

        gtag('js', new Date());
        gtag('config', 'G-8BZBG47672');
    };
}

export function initHotjar() {
    // Hotjar Tracking Code
    (function(h, o, t, j, a, r) {
        h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments); };
        h._hjSettings = { hjid: 5034748, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}
