<template>
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <router-view />
    <BackgroundProcessAlert />
    <BoxChat />
    <OrderTab />
  </main>
  <!-- <social-chat></social-chat> -->
  <div class="cookie-container hide w-100">
    <div class="container">
      <p class="cookie-text text-sm" v-html="getSetting.acceptCookie"></p>
      <div class="agree d-flex gap-2 justify-content-center">
        <a href="/policy" class="btn btn-outline-white">อ่านเพิ่มเติม</a>
        <button @click.prevent="confirmCookie" class="btn btn-outline-danger">ยืนยัน</button>
      </div>
    </div>
  </div>
</template>
<script>
// import Sidenav from "./examples/Sidenav";

import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
// import SocialChat from '@/views/pages/components/SocialChat.vue';
import OrderTab from "./views/pages/components/OrderTab.vue"
import BackgroundProcessAlert from "./components/BackgroundProcessAlert.vue";
import BoxChat from "./components/BoxChat.vue";
export default {
  name: "App",
  components: {
    // Sidenav,
    // SocialChat
    OrderTab,
    BackgroundProcessAlert,
    BoxChat
  },
  data() {
    return {
      cookie_confirm: window.localStorage.getItem("cookie_confirm") || false,
    }
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "isNavFixed",
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "color",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
    ...mapGetters(["getSetting"]),
  },
  async created() {
    await this.getSettingCMS().then( () => {
      const primary_color = `${this.getSetting.primary_color} !important`;
      const light_color = `${this.getSetting.light_color} !important`;
      const danger_color = `${this.getSetting.danger_color} !important`;
      const warning_color = `${this.getSetting.warning_color} !important`;
      const dark_color = `${this.getSetting.dark_color} !important`;

      const MAIN_CSS_COLOR = `
        .text-danger, text-primary {
          color: ${this.getSetting.primary_color} !important;
        }
        .btn-danger {
          background-color: ${this.getSetting.primary_color} !important;
          &:focus {
            box-shadow: none !important;
          }
        }
        .btn-outline-danger {
          color: ${this.getSetting.primary_color} !important;
          border-color: ${this.getSetting.primary_color} !important;
        }
        .border-bottom {
          border-bottom: 2px solid ${this.getSetting.primary_color} !important;
          box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px ${this.getSetting.primary_color}, 0 0 20px ${this.getSetting.primary_color}!important;
        }
        .navbar-toggler-bar{
          background-color: ${this.getSetting.primary_color} !important;
        }
        .mobile-wallet{
          background-color: ${this.getSetting.primary_color} !important;
        }
        .carousel {
          .carousel__pagination-button {
            &.carousel__pagination-button--active {
              &::after {
                background-color: ${this.getSetting.primary_color} !important;
              }
            }
          }
        }
        .-item {
          border-color: ${this.getSetting.primary_color} !important;
          h4 {
            color: ${this.getSetting.primary_color} !important;
          }
          &.item-active {
            background-color: ${this.getSetting.primary_color} !important;
            &:hover {
              background: ${this.getSetting.primary_color} !important;
            }
            h4 {
              color: #fff !important;
            }
          }
        }
        .form-control,
        .form-control:disabled, .form-control[readonly] {
            &:focus {
                box-shadow: 0 0 5px 2px ${this.getSetting.primary_color} !important;
            }
        }
        .footer-nav {
          a {
            &:hover {
              color: ${this.getSetting.primary_color} !important;
            }
          }
        }

        .card-game {
          background: #1d1d1d !important;
          .btn {
            background: #2c2c2c !important;
          }
          &:hover {
            outline: 1px solid ${this.getSetting.primary_color} !important;
            background: #1d1d1d !important;
            &:not(.-item) {
              strong {
                color: ${this.getSetting.primary_color} !important;
              }
              .btn {
                background: ${this.getSetting.primary_color} !important;
                color: #000 !important;
              }
            }
          }
        }
        .boxNumber{
          background: ${this.getSetting.primary_color} !important;
        }
        .seo_title{
          color: ${this.getSetting.primary_color} !important;
        }
        .page-item.active .page-link{
          background: ${this.getSetting.primary_color} !important;
        }
        .btn-status{
          background: ${this.getSetting.primary_color} !important;
        }
      `;

      // console.log(primary_color);
      const CMS_COLOR = document.createElement('style');
      CMS_COLOR.appendChild(document.createTextNode(MAIN_CSS_COLOR));
      document.head.appendChild(CMS_COLOR);
    });

  },
  beforeMount() {
    this.$store.state.defaultStore.isTransparent = "bg-transparent";
  },

  mounted() {
    if(!this.cookie_confirm) {
      const cookieContainer = document.querySelector(".cookie-container");
      setTimeout(() => {
        cookieContainer.classList.remove("hide");
      }, 1000);
    }
  },
  methods: {
    confirmCookie() {
      window.localStorage.setItem("cookie_confirm", true);
      const cookieContainer = document.querySelector(".cookie-container");
      cookieContainer.classList.add("hide");
    },
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    ...mapActions(["getSettingCMS"]),
  },
  watch: {
    '$route': function(to, from) {
      window.scrollTo(0,0);
      const element = document.getElementById('freechat-popup');
      if (element && element.classList.contains('freechat-popup-show')) {
          element.classList.remove('freechat-popup-show');
      }
    }
  }
};
</script>

<style lang="scss">
  body {
    background-color: #000 !important;
    font-family: 'Prompt', Helvetica, sans-serif !important;

    &::after {
      content: '';
      position: fixed;
      z-index: -2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-image: url('~@/assets/img/jcr-bg.jpg');
      background-attachment: fixed;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: .15;
    }
  }

  .cookie-container {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 1rem 2rem;
    position: fixed;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(10px);
    color: #fff;
    z-index: 9999;
    bottom:0;
    font-size: 1rem;
    gap: 2rem;
    opacity: 1;
    visibility: visible;
    flex-wrap: wrap;
  }

  .cookie-container.hide {
    opacity: 0;
    visibility: hidden;
  }

  .cookie-container .cookie-text {
    flex: 8 768px;
  }

  .cookie-container .agree {
    flex: 1 150px;
    text-align: center;
  }
  .swal2-toast-shown {
    .swal2-container {
      height: auto !important;
    }
  }
  // #freechatpopup {
  //   z-index: 9998;
  // }
  // .freechat-popup-content img {
  //   background-color: #000 !important;
  // }
  // .freechat-popup-notinput a, .freechat-popup-header, .freechat-icon {
  //   background-color: #F37421 !important;
  // }
  // .freechat-popup-top h1 {
  //   color: #fff !important;
  // }
</style>