import { createRouter, createWebHistory } from "vue-router";
import Basic from "../views/auth/signin/Basic.vue";
import BasicOTP from "../views/auth/signin/BasicOTP.vue";
import SignupBasic from "../views/auth/signup/Basic.vue";
import AuthLine from "../views/auth/line.vue";
import AuthGoogle from "../views/auth/google.vue";
import AuthFacebook from "../views/auth/facebook.vue";
import HomePage from "../views/pages/HomePage.vue";
import CoinTopUp from "../views/pages/CoinTopUp.vue";
import GameTopUpSingle from "../views/pages/GameTopUpSingle.vue";
import Policy from "../views/pages/Policy.vue";
import Terms from "../views/pages/Terms.vue";
import Article from "../views/pages/Article.vue";
import ArticleSingle from "../views/pages/ArticleSingle.vue";
import MemberProfile from "../views/pages/MemberProfile.vue";
import MemberOrder from "../views/pages/MemberOrders.vue";
import MemberOrderTopup from "../views/pages/MemberOrdersTopup.vue";
import CheckOrder from "../views/pages/CheckOrder.vue"
import store from "../store";
import RedeemMember from "../views/pages/RedeemMember.vue"
import GameIdPass from "../views/pages/GameIdPass.vue"
import CartOrder from "../views/pages/CartOrder.vue"
import GameAuto from "../views/pages/GameAuto.vue"
import CashCard from "../views/pages/CashCard.vue"
import Remember from "../views/pages/components/Remember.vue"

const routes = [
  {
    path: "/",
    name: "/",
    component: HomePage
  },
  {
    path: "/member/order",
    name: "MemberOrder",
    component: MemberOrder,
  },
  {
    path: "/member/redeem",
    name: "RedeemMember",
    component: RedeemMember,
  },
  {
    path: "/member/order/topup",
    name: "MemberOrderTopup",
    component: MemberOrderTopup,
  },
  {
    path: "/member/profile",
    name: "MemberProfile",
    component: MemberProfile,
  },
  {
    path: "/member",
    name: "member",
    redirect: '/member/profile'
  },
  {
    path: "/profile",
    name: "profile",
    redirect: '/member/order'
  },
  {
    path: "/coin-topup",
    name: "Coin TopUp",
    component: CoinTopUp,
  },
  {
    path: "/game-topup/:game_id",
    name: "GameTopUpSingle",
    component: GameTopUpSingle,
  },
  {
    path: "/topup-id-pass",
    name: "GameIdPass",
    component: GameIdPass,
  },
  {
    path: "/game-topup",
    name: "GameAuto",
    component: GameAuto,
  },
  {
    path: "/cash-card",
    name: "CashCard",
    component: CashCard,
  },
  {
    path: "/check-order",
    name: "checkOrder",
    component: CheckOrder,
  },
  {
    path: "/article",
    name: "Article",
    component: Article,
  },
  {
    path: "/article/:article",
    name: "ArticleSingle",
    component: ArticleSingle,
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/terms-of-services",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/signin",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/verify",
    name: "Verify Basic",
    component: BasicOTP,
  },
  {
    path: "/signup",
    name: "Signup Basic",
    component: SignupBasic,
  },
  {
    path: "/remember-pass",
    name: "Remember",
    component: Remember,
  },
  {
    path: "/auth/line",
    name: "Auth Line",
    component: AuthLine,
  },
  {
    path: "/auth/facebook",
    name: "Auth Facebook",
    component: AuthFacebook,
  },
  {
    path: "/auth/google",
    name: "Auth Google",
    component: AuthGoogle,
  },
  {
    path: "/cart-order",
    name: "CartOrder",
    component: CartOrder,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});


const orderRelatedRoutes = ['checkOrder'];

router.beforeEach((to, from, next) => {
  console.log("เริ่มกระบวนการยิง api กรณีออกจากเว็บ");
  if (orderRelatedRoutes.includes(from.name) && !orderRelatedRoutes.includes(to.name)) {
    if (!store.getters.isProcessingOrder) {
      store.dispatch('createIncompleteOrderInBackground').catch(error => {
        console.error('Background process error:', error);
      });
    } else {
      console.log("กำลังประมวลผลคำสั่งซื้อ ไม่เริ่มกระบวนการใหม่");
    }
  }
  next();
});

export default router;
