export default {
  close: "ปิด",
  successTitle: "สำเร็จ",
  errorTitle: "ผิดพลาด",
  important: "สำคัญ",
  submit: "ตกลง",
  contactAdmin: "ติดต่อแอดมิน",
  cancle: "ยกเลิก",
  copy: "คัดลอก",
  menu: {
    cashCard: "บัตรเงินสด",
    idPass: "เติมเกมส์ ID - PASS",
    toupAuto: "เติมเกมส์อัตโนมัติ",
  },
  home: "หน้าหลัก",
  topup_id_pass: "เติมเกม ID/PASS",
  coin_topup: "เติม เครดิต",
  news_promotions: "ข่าวสารและโปรโมชั่น",
  buy_sell_items: "ซื้อขายไอเท็ม",
  contact_us: "ติดต่อเรา",
  profile: "โปรไฟล์",
  sign_in: "เข้าสู่ระบบ",
  gameUIDTitle: "ด้วยระบบเติมเกมด้วย UID อัตโนมัติ",
  gameUIDDescription: "บริการรวดเร็ว พร้อมทีมงานบริการลูกค้าตลอด 24 ชั่วโมง",
  gameServiceTitle: `บริการเติมเกม และ ขายบัตรของขวัญ Gift card ทั้ง Garena card, <span><a href="https://gold.razer.com/us/en" class="text-danger">razer gold</a></span> gift card game indy และอื่นๆอีกมากมาย`,
  gameServiceDescription: "บริการรวดเร็ว พร้อมทีมงานบริการลูกค้าตลอด 24 ชั่วโมง",
  gameIDPassTitle: "ด้วยระบบเติมเกมผ่าน ID - Pass",
  gameIDPassDescription: "สามารถเลือก package จากบริการของเราหรือจะ capture รูป package จากเกมที่ต้องการ ส่งมาให้เราดำเนินการ พร้อมทีมงานบริการลูกค้าตลอด 24 ชั่วโมง",
  navbar: {
    signUp: "สมัครสมาชิก",
    signIn: "เข้าสู่ระบบ",
    dashboard: "ตรวจสอบข้อมูล",
    topup: "เติมเงิน",
  },
  homepage: {
    choose: "เลือก",
    more: "แสดงเพิ่มเติม",
    all: "ทั้งหมด",
    new: "มาใหม่",
    hot: "ยอดนิยม",
    searchGame: "ค้นหาเกม..",
    topupGame: "เติมเกม",
    topupCoin: "เติมเครดิต",
    policy: "นโยบายความเป็นส่วนตัว",
    condition: "เงื่อนไขการให้บริการ",
    notFoundGame: "ไม่พบเกมส์",
    viewMore: 'ดูทั้งหมด'
  },
  faq: {
    title: "คำถามที่พบบ่อย Q&A",
    questions: [
      {
        question: "สมัครสมาชิกอย่างไร",
        answer: `การสมัครสมาชิก สามารถสมัครได้ง่ายๆ เพียงกดคลิ๊กที่สมัครสมาชิก หรือ
        <span class="text-danger fw-bold cursor-pointer" @click="$router.push('/signup')">"คลิ๊กที่นี่"</span>
        โดยการสมัคร สามารถสมัครได้ทั้ง Google account, Line`,
      },
      {
        question: "เติมแบบอัตโนมัติหรือไม่",
        answer:
          "เว็บไซต์ goldenbug เติมเกม มีระบบเติมเกมแบบอัตโนมัติ ที่พร้อมรับลูกค้าเป็นจำนวนมากพร้อมๆกัน",
      },
      {
        question: "เปิดบริการ 24 ชั่วโมงหรือไม่",
        answer:
          "เว็บไซต์ โกลเด้นบักเติมเกม เปิดบริการ 24 ชั่วโมง โดยไม่มีเวลาปิดทำการ เพื่อความสนุกอย่างต่อเนื่องสำหรับผู้เล่นเกม",
      },
      {
        question: "เติมเงินเข้าเว็บรอนานหรือไม่",
        answer:
          "โดยปกติการเติมเงิน (เครดิต) เข้าเว็บไซต์ จะใช้เวลาประมาณ 0.30-01.00 นาทีเท่านั้น ซึ่งถือเป็นเวลาที่รวดเร็ว",
      },
      {
        question: "ติดต่อเจ้าหน้าที่ได้อย่างไร",
        answer: `ลูกค้าสามารถติดต่อเจ้าหน้าที่ผ่านช่องทาง line Office โดยเพิ่มเพื่อนที่ @710oclzg หรือ
        <a class="text-danger fw-bold" href="https://lin.ee/N0xyBar" target="_blank">"คลิ๊กที่นี่"</a>`,
      },
    ],
  },
  seo: {
    title: "Goldenbug เติมเกม 24 ชั่วโมง",
    subtitle: "Goldenbug รับเติมเกมส์ออนไลน์ uid 24 ชั่วโมง",
    content: "Goldenbug เติมเกม 24 ชั่วโมง",
    details:
      "ด้วยระบบเติมเกมด้วย UID อัตโนมัติ บริการรวดเร็ว พร้อมทีมงานบริการลูกค้าตลอด 24 ชั่วโมง โกลเด้นบัค เรามีบริการเติมเกม และ ขายบัตรของขวัญ Gift card ทั้ง Garena card,",
    link: "razer gold gift card",
    footer:
      "game indy และอื่นๆอีกมากมาย และเรายังมีทีมงานซัพพอตที่คอยช่วยเหลือผู้ใช้งานเว็บไซต์ ตลอด 24/7 อีกด้วย",
  },
  coinTopup: {
    signup: "สมัครสมาชิก",
    amount: "ระบุจำนวนเงิน",
    amountPayment: " ระบุจำนวนเงินที่ต้องการเติม ",
    amountClick: "ระบุจำนวนเงิน คลิกที่นี่",
    minimunAmount: "ระบุจำนวนเงิน ขั้นต่ำ 50",
    recommend: "แนะนำ",
    hot: "ขายดี",
    payment: "เลือกช่องทางชำระเงิน",
    bank: "ธนาคาร",
    wallet: "ทรูวอลเล็ต",
    membership: "สมัครสมาชิก เพื่อรับสิทธิพิเศษมากมาย คลิกที่นี่",
    proForYou: "โปรโมชั่นสำหรับคุณ",
    notPro: "ไม่พบโปรโมชั่นสำหรับคุณ",
    view: "ดูรายละเอียด",
    packageDetail: "รายละเอียดแพ็คเกจ",
    conditionPro1: "เงื่อนไข: สามารถรับได้จนกว่า แอดมินจะปิดโปรโมชั่นนี้",
    conditionPro2: "เงื่อนไข: โปรโมชั่นนี้คุณสามารถรับได้เพียง 1 ครั้งเท่านั้น",
    conditionPro3:
      "เงื่อนไข: โปรโมชั่นมีจำนวนจำกัด สามารถรับได้จนกว่าโปรโมชั่นจะหมด",
    conditionPro4:
      "เงื่อนไข: โปรโมชั่นนี้สำหรับผู้ใช้งานที่ยังไม่เคยเติมเงินกับ Goldenbug",
    conditionPro5:
      "เงื่อนไข: โปรโมชั่นนี้สำหรับ ผู้ใช้งานที่เคยเติมเครดิตกับเรา",
    conditionPro6: "เงื่อนไข: โปรโมชั่นนี้สามารถใช้ได้ถึง",
    conditionPro7: "(ไม่นับรายการที่เคยเติมผ่านโปรโมชั่น)",
    expirePro: "หรือจนกว่าโปรโมชั่นจะหมด",
    count: "ครั้ง",
    bath: "บาท",
    into: "จนถึง",
    price: "ราคา",
    bonus: "โบนัส",
    usePro: "ใช้สิทธิ",
    tran: "ยอดที่ต้องโอน",
    tranAttach: "โอน / แนบสลิป",
    copy: "คัดลอก",
    readyCopy: "คัดลอกยอดเงินที่ต้องโอนแล้ว",
    bankCopy: "คัดลอกเลขที่บัญชีแล้ว",
    attach: "แนบสลิป",
    deleteFile: "ลบภาพ",
    submitForm: "ยืนยันการทำรายการ",
    closeCoine: "ระบบเติม เครดิต ปิดใช้งานชั่วคราว",
    whyGolden: "ทำไมต้องเติมเกมกับ GoldenBug",
    title1: "เติมปุ๊บ เงินเข้าไว!",
    desc1: "ระบบออโต้เติมเงิน ภายใน 1-3 นาที รวดเร็ว ทันใจ ไม่ต้องรอนาน",
    title2: "เติมได้ทุกที่ ทุกเวลา",
    desc2:
      "บริการ 24 ชั่วโมง มีแอดมินคอยดูแล พร้อมให้ความช่วยเหลือตลอด 24 ชั่วโมง",
    title3: "สะดวก ปลอดภัย",
    desc3: "เติมเงินผ่าน UID ไม่ต้องแจ้ง User และ Password ปลอดภัยไร้กังวล",
    tile4: "มั่นใจได้ 100%",
    desc4: "ให้บริการผ่านระบบบริษัท มีความมั่นคง เชื่อถือได้",
    history: "ประวัติการเติมเครดิต",
    ref: "รหัสอ้างอิง",
    tablePay: "จำนวนเงิน",
    timePay: "เวลาทำรายการ",
    remark: "หมายเหตุ",
    status: "สถานะ",
    pending: "รอดำเนินการ",
    inProgress: "กำลังดำเนินการ",
    success: "เติมสำเร็จ",
    fail: "ไม่สำเร็จ",
    chooseSlip: "เลือกสลิปเพื่ออัพโหลด",
    slipAgain: "เลือกภาพใหม่",
    slipTitleError: "ไม่สามารถอัพโหลดภาพได้",
    slipMessageError: "ไฟล์ภาพต้องไม่เกิน 2mb",
    confirmTitle: "ยืนยันการเติมเครดิต ?",
    confirmText:
      "เมื่อกดส่งข้อมูลแล้ว เครดิตจะเข้าภายในเวลา 15 นาที หากเกินเวลากรุณาติดต่อแอดมิน",
    confirmButtonText: "ยืนยัน เติมเครดิต",
    validateFormTopup: "โปรดระบุจำนวนเครดิตที่ต้องการเติม",
    validateFormSlip: "กรุณาแนบสลิปโอนเงินใหม่",
    validateFormSlipUrl: 'กรุณาแนบสลิป หลักฐานการโอนเงิน"',
    validateFormPackage: "โปรดเลือก แพ็คเกจที่ต้องการเติม",
    topupIsNaN: "โปรดระบุจำนวนเครดิตที่ต้องการเติมให้เป็นตัวเลข",
    topUpMinimun: "ระบุจำนวนเงินขั้นต่ำ 50",
    topUpMaximun: "หากต้องการเติมเครดิตมากกว่า 10 ล้าน โปรดติดต่อเรา",
    successMessage: "แจ้งเติมเครดิตแล้ว กำลังดำเนินการ..",
    validSignup: "กรุณาเข้าสู่ระบบ",
    rolePackage: "เมื่อเลือก แพ็คเกจ จะไม่สามารถระบุจำนวนเงินได้",
  },
  checkOrder: {
    detailHeader: "รายละเอียดการเติมเกม",
    informationUserText: "ข้อมูลผู้เติมเงิน",
    informationGameText: "ข้อมูลเกม",
    nameGame: "ชื่อเกม",
    idGame: "ID ผู้เล่น",
    serverGame: "เซิฟเวอร์",
    list: "รายการ",
    amount: "จำนวน",
    price: "ราคา/ชิ้น",
    bath: "บาท",
    total: "รวมทั้งสิ้น",
    awaitText: "*** โปรดรอสักครู่จนกว่า หน้าต่างแสดงผล จะแสดงขึ้นมา...",
    thankyouText:
      " ขอบคุณทุกท่านที่ไว้วางใจ บริการเติมเงินของ GOLDENBUG DEVELOPMENT CO., LTD..",
    undoWeb: "กลับไปหน้าหลัก",
    sendAgain: "ลองอีกครั้ง",
    recheck: "ตรวจสอบสถานะ",
    topupSuccess: "เติมเงินเรียบร้อย",
    topupError: "รายการของคุณยังไม่ถูกต้อง ...",
    contactAdmin: "กรุณาติดต่อแอดมิน",
    timeout: "เกินเวลาที่กำหนดในการรอยอดเงินอัพเดท",
    checkOrderMessage: "กำลังเช็คข้อมูล คลิกตรวจสอบสถานะ",
    creditHold: 'เครดิตที่มีอยู่,'
  },
  signIn: {
    signin: "เข้าสู่ระบบ",
    username: "ชื่อผู้ใช้งาน",
    password: "รหัสผ่าน",
    remember: "จดจำการใช้งาน",
    login: "ล็อกอิน",
    rePassword: "ลืมรหัสผ่าน",
    signup: "สมัครสมาชิก",
    loginLine: "เข้าสู่ระบบผ่าน LINE",
    loginGoogle: "เข้าสู่ระบบผ่าน Google",
    rePassword2: "แจ้งลืมรหัสผ่าน",
  },
  signUp: {
    signup: "สมัครสมาชิก",
    username: "ชื่อผู้ใช้งาน",
    password: "รหัสผ่าน",
    fullname: "ชื่อ - นามสกุล",
    phone: "เบอร์โทรศัพท์",
    email: "E-mail",
    signupLine: "สมัครผ่าน LINE",
    signupGoogle: "สมัครผ่าน Google",
    paragraph: 'ลงทะเบียน GOLDENBUG เพื่อรับสิทธิพิเศษสุดคุ้มมากมาย'
  },
  sideNav: {
    historyOrder: "ประวัติการสั่งซื้อ",
    historyTopup: "ประวัติการเติมเครดิต",
    redeem: "รหัสของขวัญ",
    dashboard: "จัดการโปรไฟล์",
    logout: "ออกจากระบบ",
  },
  historyOrder: {
    from: "จากวันที่",
    to: "ถึงวันที่",
    status: "สถานะ",
    search: "ค้นหา",
    refresh: "รีเฟรช",
    refresh1: "รีเฟรช ทุก",
    second: "วินาที",
    refresh30: "รีเฟรช ทุก 30 วินาที",
    refresh15: "รีเฟรช ทุก 15 วินาที<",
    refresh10: "รีเฟรช ทุก 10 วินาที",
    refresh5: "รีเฟรช ทุก 5 วินาที",
    total: "ทั้งหมด",
    pending: "รอดำเนินการ",
    inProcess: "กำลังดำเนินการ",
    error: "ติดปัญหา",
    success: "สำเร็จ",
    overque: "เลยคิว",
    noOrder: "หมายเลขคำสั่งซื้อ",
    contactAdmin: "ติดต่อเจ้าหน้าที่",
    information: "ข้อมูล",
    alertMessage:
      "<span class='text-decoration-underline'>กรุณารอการดำเนินการ </span>เจ้าหน้าที่จะติดต่อกลับหาท่านทางไลน์ที่ให้ไว้ หรือสอบถามเพื่อตรวจสอบขั้นตอนได้ที่ไลน์ <a href='https://lin.ee/N0xyBar' target='_blank' class='fw-bold text-danger'>คลิกที่นี้</a>",
    notFoundOrder: "ไม่พบรายการสั่งซื้อ",
    closeWindow: "ปิดหน้าต่าง",
    copySuccess: "คัดลอกแล้ว",
  },
  giftCode: {
    redeemTitle: "รหัสของขวัญ",
    description:
      "รหัสของขวัญใช้แทนเงินสด เพื่อเติมเงินเข้าสู่เว็บไซต์ <a href='https://termgame.goldenbug.co/' class='text-danger'>termgame.goldenbug.co</a> ใช้สำหรับ เติมเกม และซื้อบัตรเติมเงิน ภายในเว็บไซต์",
    redeem: "ตรวจสอบ",
    invalidCode: "รหัสคูปองไม่ถูกต้อง",
    enterCode: "กรุณากรอกรหัสคูปอง",
    invalidFormat: "กรอกรหัสคูปองผิดรูปแบบ",
    confirmTitle: "ยืนยันการแลกรับคูปอง",
    confirmText: "คุณต้องการแลกรับคูปองนี้หรือไม่?",
    confirmButton: "ยืนยัน",
    cancelButton: "ยกเลิก",
    successTitle: "คูปองถูกต้อง",
    successText: "เครดิตถูกเติมเรียบร้อยแล้ว",
    errorTitle: "เกิดข้อผิดพลาด",
    errorText: "ไม่สามารถแลกรับคูปองได้",
    placeholder: "กรอกรหัสของขวัญ ที่นี่...",
  },
  dashboard: {
    update: "อัพเดทข้อมูลโปรไฟล์",
    changePassword: "เปลี่ยนรหัสผ่าน",
    newPassword: "รหัสผ่านใหม่",
    confirmPassword: "ยืนยันรหัสผ่านใหม่",
  },
  passwordInstructions: {
    title: "คำแนะนำการตั้งรหัสผ่าน",
    subtitle: "โปรดทำตามคำแนะนำของเรา เพื่อปกป้องข้อมูลของคุณ:",
    rules: [
      "ใช้อักขระพิเศษอย่างน้อย 1 ตัว",
      "ความยาวรหัสผ่านอย่างน้อย 8 ตัว",
      "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว",
      "เปลี่ยนรหัสผ่านของคุณเป็นประจำ",
    ],
    confirmButton: "ยืนยันเปลี่ยนแปลงรหัสผ่าน",
  },
  accountDeletion: {
    title: "แจ้งลบบัญชีผู้ใช้ออก",
    description:
      "หากต้องการแจ้งให้บริษัทลบข้อมูลส่วนตัวและข้อมูลที่เกี่ยวกับบัญชีออก กรุณายืนยันการทำรายการ หลังจากนั้นระบบจะทำการนำข้อมูลของท่านออกจากระบบภายใน 30 วันทำการ",
  },
  gameSingle: {
    index: "หน้าหลัก",
    topup: "เติมเกม",
    writeForm: "กรอกข้อมูล",
    chooseServer: "เลือกเซิร์ฟเวอร์",
    choosePackeage: "เลือกแพ็คเกจ",
    seller: "ขายดี",
    promotion: "โปรโมชัน",
    hot: "จำนวนจำกัด",
    notFoundPackage: "ยังไม่มีแพ็คเกจ",
    selectedPackage: "แพ็คเกจที่เลือก",
    item: "ชิ้น",
    total: "ยอดรวม",
    undo: "ย้อนกลับ",
    uploadImg: "อัพโหลดรูปภาพ",
    clickImg: "คลิกเพื่อเลือกไฟล์",
    upload: "อัพโหลด",
    detailPack: "รายละเอียดแพ็คเกจที่ต้องการเติม",
    priceInGame: "ราคาแพ็คในเกม (USD)",
    amountPack: "จำนวนที่ต้องการเติม",
    description: "คำอธิบาย(เพิ่มเติม)",
    exDescription:
      "กรุณากรอกคำอธิบายเพิ่มเติมที่นี่ ( EX เช่น ต้องการเติมรูปที่ 1 จำนวน 3 แพ็ค ราคาในเกม 100 บาท)",
    price: "ราคา",
    amount: "จำนวน",
    inclound: "รวม",
    agree: "ฉันเข้าใจ และยอมรับ",
    condition: "เงื่อนไขการให้บริการ",
    roles: "กรุณาอ่านและยอมรับเงื่อนไขการให้บริการ",
    submit: "ยืนยันชำระเงิน",
    notPromotion: "คุณยังไม่มีโปรโมชั่นที่สามารถรับได้",
    thisPromotion: "โปรโมชั่นของคุณ",
    rolesPromotion:
      "เงื่อนไข: หากต้องการใช้โปรโมชั่นต้องเติมผ่านเครดิตเท่านั้น !!",
    package: "กรุณาเลือกแพ็คเกจที่ต้องการ",
    promotionTitle: "เติมเข้ากระเป๋า Goldenbug คุ้มกว่า",
    promotionDesc:
      "เครดิตเงินเพิ่ม <span class='text-danger'>10%</span> เมือทำการเติมเกมผ่านกระเป๋า Goldenbug",
    pricePay: "ยอดที่ต้องชำระ",
    submitPay: "ยืนยันชำระเงิน",
    sendForm: "ส่งข้อมูลให้แอดมิน",
    rolesLogin: "เข้าสู่ระบบ ก่อนสั่งซื้อ",
    validSlip: "กรุณาแนบสลิปโอนเงินก่อนยืนยันการสั่งซื้อ",
    dataOrder: "สรุปคำสั่งซื้อ",
    imgOrder: "กรุณาแนบรูปภาพที่ต้องการเติม",
    submitOrder: "ยืนยันการซื้อ ?",
    warningText:
      "เมื่อกดส่งข้อมูลแล้ว จะไม่สามารถแก้ไขย้อนหลังได้\nในกรณีที่ลูกค้ากรอกข้อมูลผิดและระบบได้ทำการเติมเงินไปแล้ว ลูกค้าจะไม่สามารถขอเครดิตคืนได้ทุกกรณี",
    confirmButton: "ยืนยัน สั่งซื้อ",
    warningText2:
      "เมื่อกดส่งข้อมูลแล้ว จะไม่สามารถแก้ไขย้อนหลังได้\nในกรณีที่ลูกค้ากรอกข้อมูลผิดกรุณาแจ้งแอดมินที่ไลน์ Official เมื่อยืนยันจะย้ายไปสู่หน้ารอผลดำเนินการเพื่อดำเนินการต่อไป",
    validPackage: "โปรดเลือกแพ็คเกจที่ต้องการซื้อ",
    rolesImg: "คุณสามารถอัพโหลดได้สูงสุด 3 ภาพ",
    messageErrorImg: "เกิดข้อผิดพลาดในการอัพโหลดภาพ",
  },
  orderHold: {
    title: "รายการที่กำลังดำเนินการ",
    notHold: "ไม่มีรายการที่กำลังดำเนินการ",
  },
  remember:{
    warningTitle: 'ข้อมูลไม่ถูกต้อง',
    warningText: 'คุณสามารถเปลี่ยนรหัสผ่านด้วยตัวเองได้หากลืมรหัสผ่าน',
    cancelButtonText: 'เปลี่ยนรหัสผ่าน',
    confirmButtonText: 'ลองอีกครั้ง',
    loading: 'กำลังโหลดข้อมูล รีเฟรช...',
    confirmPasswordChange: 'ยืนยันการเปลี่ยนรหัสผ่าน?',
    close: 'ปิด',
    confirm: 'ยืนยันการเปลี่ยนรหัสผ่าน',
    success: 'สำเร็จ',
    successText: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว',
    error: 'ไม่สำเร็จ',
    errorText: 'ไม่สามารถเปลี่ยนรหัสผ่านได้',
    validate: 'กรุณากรอกรหัสผ่านให้ตรงกัน',
    contactAdmin: 'แจ้งเปลี่ยนรหัสกับแอดมิน'
  }
};
