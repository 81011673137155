<template>
  <navbar :is-blur="true" :dark-mode="true" />
        
  <div class="container mt-6" :style="`--jcr-main-bgcolor: ${getSetting.page.home_page.bgcolor};`">
    <div class="row">
      <div class="col-lg-7 text-center mx-auto mt-6">
        <h1 class="text-white text-xl">{{ $t('sideNav.dashboard')}}</h1>
      </div>
    </div>
  </div>

  <div class="container mt-4">
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-8">
        <!-- <nav-pill /> -->
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-3">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <profile />
      </div>
    </div>
  </div>
  <app-footer />
</template>
  
  <script>
  import Navbar from "@/examples/PageLayout/Navbar.vue";
  import SideNav from "@/views/pages/Account/components/SideNav.vue";
  import Profile from "@/views/pages/MemberProfileEdit.vue";
  import AppFooter from "@/examples/PageLayout/Footer.vue";
  import { mapGetters } from "vuex";
  
  export default {
    name: "MemberProfile",
    components: {
      Navbar,
      SideNav,
      Profile,
      AppFooter
    },
    computed: {
      ...mapGetters(['getSetting'])
    }
  };
  </script>  