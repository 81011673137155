import { createStore } from "vuex";

import defaultStore from './modules/defaultStore';
import gameStore from './modules/gameStore';
import bannerStore from './modules/bannerStore';
import productStore from './modules/productStore';
import orderStore from './modules/orderStore';
import bankAccountStore from './modules/bankAccountStore';
import authStore from './modules/authStore';

export default createStore({
  modules: {
    defaultStore,
    gameStore,
    bannerStore,
    productStore,
    orderStore,
    bankAccountStore,
    authStore,
  }
});
