import axios from "axios";
// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://termgame-api.goldenbug.co/api";

export default {
  state() {
    return {
      orders: [],
      order: null,
      token: window.localStorage.getItem("jcr_token") || "",
      myCredit: 0,
      myCreditTransactions: [],
      qrCode: null,
      //existing mutations
      incompleteOrder: null,
      backgroundProcessStatus: null,
      isProcessingOrder: false,
      promotions: [],  // เพิ่ม state สำหรับ promotions
      giftCard: [],  // เพิ่ม state สำหรับ promotions

    };
  },
  mutations: {
    SET_QR_CODE_IMAGE(state, qrcode) {
      state.qrCode = qrcode;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setOrder(state, order) {
      state.order = order;
    },
    addOrder(state, order) {
      state.orders.push(order);
    },
    updateOrder(state, updatedOrder) {
      const index = state.orders.findIndex(
        (order) => order._id === updatedOrder._id
      );
      if (index !== -1) {
        state.orders[index] = updatedOrder;
      }
    },
    removeOrder(state, orderId) {
      state.orders = state.orders.filter((order) => order._id !== orderId);
    },
    SET_MY_CREDIT(state, credit) {
      state.myCredit = credit;
    },
    SET_MY_CREDIT_TRANS(state, credit) {
      state.myCreditTransactions = credit;
    },
    SET_WALLET(state, credit) {
      state.myCredit = credit;
    },
    //existing mutations
    SET_INCOMPLETE_ORDER(state, order) {
      state.incompleteOrder = order;
    },
    CLEAR_INCOMPLETE_ORDER(state) {
      state.incompleteOrder = null;
    },
    SET_BACKGROUND_PROCESS_STATUS(state, status) {
      state.backgroundProcessStatus = status;
    },
    SET_PROCESSING_ORDER(state, value) {
      state.isProcessingOrder = value;
    },
    SET_PROMOTIONS(state, promotions) {
      state.promotions = promotions;
    },
    SET_GIFTCARD(state, giftCardData) {
      state.giftCard = giftCardData; 
    }
  },
  actions: {
    async getHistoryTopupCredit({ commit, state }, data) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(
          `${API_URL}/credit/customerGetHistoryTopupCredit`,
          data
        );
        commit("SET_MY_CREDIT_TRANS", response.data.datas);
      } catch (error) {
        console.log("An error occurred while fetching orders: ", error);
      }
    },
    async genQrCode({ commit, state }, data) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(`${API_URL}/promptpay/genqr`, data);
        const svgString = response.data.datas.svg; // ดึงค่า svg จาก response
        commit("SET_QR_CODE_IMAGE", svgString);
      } catch (error) {
        console.log("An error occurred while generating QR code: ", error);
      }
    },
    async topupCredit({ commit, state }, data) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(
          `${API_URL}/credit/createTopupCredit`,
          data
        );
        return response;
      } catch (error) {
        console.log("An error occurred while fetching orders: ", error);
      }
    },
    async getMyCredit({ commit, state }) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(`${API_URL}/credit/getMyCredit`);
        commit("SET_MY_CREDIT", response.data.credit_total);
      } catch (error) {
        console.log("An error occurred while fetching orders: ", error);
      }
    },
    async fetchMyCredit({ commit, state }) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(`${API_URL}/credit/getMyCredit`);
        commit("SET_WALLET", response.data.credit_total);
        return response.data.credit_total;
      } catch (error) {
        console.log("An error occurred while fetching orders: ", error);
      }
    },
    async fetchOrders({ commit, state }) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(`${API_URL}/order/getList`);
        commit("setOrders", response.data.datas);
      } catch (error) {
        console.log("An error occurred while fetching orders: ", error);
      }
    },
    async fetchOrderByFilter({ commit, state }, orderFilter) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(
          `${API_URL}/order/getList`,
          orderFilter
        );
        commit("setOrders", response.data.datas);
      } catch (error) {
        console.log("An error occurred while fetching the order: ", error);
      }
    },
    async createOrder({ commit, state }, orderData) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(
          `${API_URL}/order/create`,
          orderData,
          { headers: { "Content-Type": "application/json" } }
        );
        commit("addOrder", response.data);
        return response.data;
      } catch (error) {
        console.log("An error occurred while creating the order: ", error);
      }
    },
    async updateOrder({ commit }, orderData) {
      try {
        const response = await axios.put(
          `${API_URL}/orders/${orderData._id}`,
          orderData
        );
        commit("updateOrder", response.data);
      } catch (error) {
        console.log("An error occurred while updating the order: ", error);
      }
    },
    async deleteOrder({ commit }, orderId) {
      try {
        await axios.delete(`${API_URL}/orders/${orderId}`);
        commit("removeOrder", orderId);
      } catch (error) {
        console.log("An error occurred while deleting the order: ", error);
      }
    },
    // existing actions
    saveIncompleteOrder({ commit }, orderData) {
      commit("SET_INCOMPLETE_ORDER", orderData);
      localStorage.setItem("incompleteOrderData", JSON.stringify(orderData));
    },
    async createIncompleteOrderInBackground({ commit, dispatch, state, getters }) {
      // console.log('state.incompleteOrder: ', state.incompleteOrder);
      // console.log('!state.isProcessingOrder: ', !state.isProcessingOrder);
      if (state.incompleteOrder && !state.isProcessingOrder) {
        commit("SET_PROCESSING_ORDER", true);
        try {
          const orderData = { ...state.incompleteOrder, isComplete: false };
          let currentWallet = getters.myWallet;
          const totalPrice = orderData.totalPrice;
          console.log('currentWallet: ', currentWallet);
    
          const startTime = Date.now();
          const timeout = 1 * 60 * 1000; // ตั้งเวลาสูงสุด 1 นาที
    
          while (currentWallet < totalPrice) {
            if (Date.now() - startTime > timeout) {
              throw new Error("Exceeded maximum wait time for wallet balance to update.");
            }
    
            console.log("ยอดเงินในกระเป๋าไม่เพียงพอสำหรับการสั่งซื้อนี้");
            // รอ 5 วินาทีแล้วตรวจสอบใหม่
            await new Promise(resolve => setTimeout(resolve, 5000));
            // อัปเดต currentWallet ใหม่หลังจากรอ
            await dispatch("fetchMyCredit");
            currentWallet = getters.myWallet;
          }
    
          // เมื่อยอดเงินในกระเป๋ามากกว่าราคาทั้งหมด จึงจะสามารถสร้างคำสั่งซื้อได้
          const response = await dispatch("createOrder", orderData);
          commit("CLEAR_INCOMPLETE_ORDER");
          commit("SET_BACKGROUND_PROCESS_STATUS", "success");
          localStorage.removeItem("incompleteOrderData");
    
          return response;
        } catch (error) {
          console.error("Error creating incomplete order:", error);
          commit("SET_BACKGROUND_PROCESS_STATUS", "error");
          localStorage.removeItem("incompleteOrderData");
          throw error;
        } finally {
          commit("SET_PROCESSING_ORDER", false);
        }
      }
    },         
    loadIncompleteOrder({ commit }) {
      const savedOrder = localStorage.getItem("incompleteOrderData");
      if (savedOrder) {
        commit("SET_INCOMPLETE_ORDER", JSON.parse(savedOrder));
      }
    },
    async fetchPromotions({ commit, state }) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.get(`${API_URL}/credit/promotion/customer/get`);
        // const response = await axios.get(`http://localhost:40001/api/credit/promotion/customer/get`);
        if (response.data.success) {
          commit("SET_PROMOTIONS", response.data.datas);
        } else {
          console.error("Failed to fetch promotions:", response.data.message);
        }
      } catch (error) {
        console.error("An error occurred while fetching promotions: ", error);
      }
    },
    async useGiftCard({ commit, state }, giftCardData) {
      try {
        axios.defaults.headers.common["Authorization"] = state.token;
        const response = await axios.post(`${API_URL}/giftCard/useGiftCardCustomer`, giftCardData);
    
        // ตรวจสอบว่า response มี data และ success
        if (response && response.data) {
          if (response.data.success) {
            commit("SET_GIFTCARD", response.data.datas);
            return response.data; // ส่งข้อมูลตอบกลับกลับไปยัง component
          } else {
            console.error("ไม่สามารถใช้คูปองได้:", response.data.message);
            return { success: false, message: response.data.message }; // ส่งข้อมูลผิดพลาดกลับไปยัง component
          }
        } else {
          console.error("ไม่พบข้อมูลใน response");
          return { success: false, message: "ไม่พบข้อมูลใน response" }; // ส่งข้อมูลผิดพลาดกลับไปยัง component
        }
      } catch (error) {
        console.error("เกิดข้อผิดพลาดขณะใช้คูปอง:", error);
        return { success: false, message: "เกิดข้อผิดพลาดในการเชื่อมต่อกับเซิร์ฟเวอร์" }; // ส่งข้อมูลผิดพลาดกลับไปยัง component
      }
    }
  },
  getters: {
    topupTransaction(state) {
      return state.myCreditTransactions;
    },
    myWallet(state) {
      return state.myCredit;
    },
    fetchMyWallet(state) {
      return state.myCredit;
    },
    orders(state) {
      return state.orders;
    },
    order(state) {
      return state.order;
    },
    getQrCode(state) {
      return state.qrCode;
    },
    // existing getters
    getBackgroundProcessStatus: state => state.backgroundProcessStatus,
    isProcessingOrder: state => state.isProcessingOrder,
    promotions(state) {
      return state.promotions;
    },
    giftCard(state) {
      return state.giftCard;
    },
  },
};
