<template>
  <div>
      <div href="" id="chat-button">
        <a href="https://m.me/181215225077038" target="_blank">
          <i class="fas fa-comments"></i>
        </a>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
};
</script>

<style scoped>
#chat-button {
  position: fixed;
  bottom: 40px;
  right: 15px;
  border: none;
  z-index: 1000;
}

#chat-button a {
  width: 100%;
  height: 100%;
  width: 60px;
  height: 60px;
  background-color: #007bff;
  border-radius: 50%;
  font-size: 24px;
  color: white;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

}

#chat-button a:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  #chat-button a {
    padding: 10px 15px;
    font-size: 18px;
  }
}

</style>
