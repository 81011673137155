<template>
  <navbar :is-blur="true" :dark-mode="true" />
  <div class="container mt-8">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="title-highlight">
          <h4 class="text-white">ตะกร้าสินค้า</h4>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <!-- ฝั่งซ้าย: รายการสินค้า -->
      <div class="col-md-8">
        <div class="card blur-dark-fade sticky-top z-index-1 top-10">
          <div class="card-body">
            <h2 class="mb-3 fs-4 text-white">รายการสินค้า</h2>
            <div style="width: 100%; height:1px; background-color: white"></div>
            <div v-for="item in cartItems" :key="item.id" class="my-4">
              <div class="row g-0 align-items-center">
                <div class="col-md-3 d-flex justify-content-center align-items-center">
                  <!-- <img src="../../../dist/img/เติมเกม24ชั่วโมง.8a4a893c.jpg" class="img-fluid rounded-start image-item"> -->
                </div>
                <div class="col-md-9">
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="card-title col-md-5">{{ item.name }}</h5>
                    <p class="card-text text-center col-md-_ mb-0">{{ item.price }} บาท / รายการ</p>
                    <div class="quantity-input col-md-4">
                      <button class="btn-quantity" style="border-radius: 10px 0 0 10px;" type="button" @click="decreaseQuantity(item)">-</button>
                      <input type="text" class="form-control quantity-value" :value="item.quantity" readonly>
                      <button class="btn-quantity" style="border-radius: 0 10px 10px 0" type="button" @click="increaseQuantity(item)">+</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ฝั่งขวา: ยอดรวมและช่องทางการชำระเงิน -->
      <div class="col-md-4">
        <div class="card blur-dark-fade">
          <div class="card-body">
            <h5 class="card-title mb-1">สรุปคำสั่งซื้อ</h5>
            <ul class="list-group list-group-flush blur-dark-fade">
              <li class="list-group-item d-flex justify-content-between align-items-center blur-dark-fade text-white">
                ยอดรวม
                <span>{{ totalAmount }} บาท</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center blur-dark-fade text-white">
                ส่วนลด
                <span>{{ discount }} บาท</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-center fw-bold blur-dark-fade text-white">
                รวมทั้งสิ้น
                <span>{{ grandTotal }} บาท</span>
              </li>
            </ul>
            <div style="width: 100%; height:1px; background-color: white"></div>

            <form id="frm_promptpay" @submit.prevent="confirmDigit()">
              <div class="mb-5">
                <h6 class="mt-4 text-white">ช่องทางการชำระเงิน</h6>
                <div class="row">
                  <div class="col-12">
                    <div class="row" v-if="banks && banks.length">
                      <div class="col-6 mb-3" v-for="(bank, index) in banks" :key="index">
                        <div v-if="bank.status_active" class="payment-method-card" :class="{ 'selected': selectedPaymentMethod == '' }" @click.prevent="selectPaymentMethod(bank.bank_type)">
                          <i class="fas fa-university"></i>
                          <span>ธนาคาร {{ bank.bank_name }}</span>
                          <i v-if="selectedPaymentMethod == ''" class="fas fa-check-circle check-icon"></i>
                        </div>
                        <div v-else class="payment-method-card" style="filter: grayscale(1); opacity: 0.5">
                          <i class="fas fa-ban"></i>
                          <h5 class="m-0 text-white">{{ bank.bank_name }}</h5>
                          <span>ยังไม่พร้อมใช้งาน {{ bank.bank_name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label class="form-label text-danger text-lg"
                      >ยอดที่ต้องโอน</label
                    >
                    <h2
                      class="text-white d-flex justify-content-start gap-3 align-items-center"
                    >
                    {{ grandTotal }} บาท
                      <span
                        ><button
                          @click.prevent="
                            copyToClipboard(
                              grandTotal  ,
                              'คัดลอกยอดเงินที่ต้องโอนแล้ว'
                            )
                          "
                          class="btn btn-xs btn-outline-white m-0 p-1"
                        >
                          <i class="fas fa-copy"></i> คัดลอก
                        </button></span
                      >
                    </h2>
                  </div>
                  <!-- <label class="form-label text-danger text-sm" for="promptpay_amount">เลือกวิธีชำระเงิน</label> -->
                  <div
                    class="col-md-12 mb-3"
                    v-for="(bank, index) in banks"
                    :key="index"
                  >
                    <div
                      v-if="bank.status_active"
                      @click.prevent="selectPaymentMethod(bank)"
                    >
                      <h4 class="mb-2 text-white">โอน / แนบสลิป</h4>
                      <div class="d-flex gap-3 align-items-center">
                        <div class="bank-logo">
                          <!-- <img
                            class="img-fluid"
                            width="60"
                            src="../../../dist/img/เงินเข้าไว.9891e51f.png"
                            alt=""
                          /> -->
                        </div>
                        <div class="bank-detail">
                          <h5 class="m-0 text-white">{{ bank.bank_name }}</h5>
                          <h5
                            class="m-0 text-white d-flex justify-content-between align-items-center"
                          >
                            {{ bank.account_no }}
                            <button
                              @click.prevent="copyBank(bank)"
                              class="btn btn-xs btn-outline-white m-0 p-1 ms-2"
                            >
                              <i class="fas fa-copy"></i> คัดลอก
                            </button>
                          </h5>
                          <h6 class="m-0 text-white">
                            {{ bank.account_name }}
                          </h6>
                        </div>
                      </div>
                      <div
                        class="row"
                        v-if="
                          topup.bank_id == bank._id &&
                          (bank.code === 3 || bank.qr_code_img)
                        "
                      ></div>
                    </div>
                    <div
                      v-else
                      class="p-2 blur-dark shadow-blur cursor-pointer border-radius-md position-relative"
                      style="filter: grayscale(1); opacity: 0.5"
                    >
                      <div class="d-flex gap-3 align-items-center">
                        <div class="bank-logo">
                          <img
                            class="img-fluid"
                            width="60"
                            :src="bank.url_img"
                            alt=""
                          />
                        </div>
                        <div class="bank-detail">
                          <h5 class="m-0 text-white">{{ bank.bank_name }}</h5>
                          <h5
                            class="m-0 text-white d-flex justify-content-between align-items-center"
                          >
                            ยังไม่พร้อมใช้งาน
                          </h5>
                          <h6 class="m-0 text-white">
                            {{ bank.account_name }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-4 col-6">
                    <!-- QR Code container with v-html -->
                    <div
                      v-html="qrCodeImage"
                      class="w-100"
                      @click="downloadQRCode"
                      :class="{ 'animate-download': animationClass }"
                      style="cursor: pointer;"
                    ></div>
                
                    <!-- Hidden link for download -->
                    <a ref="downloadLink" :href="downloadUrl" download="qrcode.svg" style="display: none;"></a>
                  </div>
                  <div class="col-md-8 mt-3 col-6">
                    <button
                      class="btn btn-xs btn-outline-white m-0 p-1"
                      @click="downloadQRCode"
                    >
                      <i class="fas fa-download"></i> Download QRCode
                    </button>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-12 mb-3">
                    <label for="slip">แนบสลิป</label>
                    <form
                      id="dropzone2"
                      ref="dropzone2"
                      :action="`${API_URL}/file/upload`"
                      class="form-control dropzone d-flex justify-content-start align-items-center text-center"
                      @click="resetSlip()"
                    >
                      <div class="fallback">
                        <input name="file" type="file" />
                      </div>
                      <a
                        v-if="topup.receipt_url"
                        class="order-2 dz-remove btn btn-danger z-index-1 cursor-pointer mt-3"
                        role="button"
                        @click="resetSlip()"
                        >ลบภาพ</a
                      >
                    </form>
                  </div>
                </div>
              </div>
              <div>
                <div class="row">
                  <button
                    type="button"
                    class="btn btn-outline-danger fs-5 w-100"
                    @click="confirmPayment()"
                  >
                    ยืนยันการทำรายการ
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import Dropzone from "dropzone";
// const API_ROOT_URL = process.env.VUE_APP_API_URL;
const API_ROOT_URL = "https://termgame-api.goldenbug.co/api";

import AOS from "aos";
import "aos/dist/aos.css";
import Rellax from "rellax";

export default {
  name: "CartOrder",
  components: {
    Navbar,
    AppFooter,
  },
  data() {
    return {
      cartItems: [
        { id: 1, name: "สินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า Aสินค้า A", price: 100, quantity: 1, image: "/path/to/image-a.jpg" },
        { id: 2, name: "สินค้า B", price: 200, quantity: 2, image: "/path/to/image-b.jpg" },
      ],
      discount: 50,
      paymentMethod: "credit_card",
      topup: {
        creadit_total_topup: "",
      },
      selectedPaymentMethod: null,

    }
  },
  async created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    this.fetchBanks();
    if (this.getMemberProfile && this.getMemberProfile._id) {
      this.topup._id = this.getMemberProfile._id;
    }
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    AOS.init();
    if (document.getElementsByClassName("rellax")) {
      new Rellax(".rellax", {
        speed: 2,
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: true,
      });

      //   rellax.destroy();
    }

    setTimeout(() => {
      Dropzone.autoDiscover = false;

      this.slipDropzone = new Dropzone(this.$refs.dropzone2, {
        // addRemoveLinks: true,
        dictDefaultMessage: "เลือกสลิปเพื่ออัพโหลด",
        dictRemoveFile: "เลือกภาพใหม่",
        maxFiles: 1,
        init: function () {
          this.on("maxfilesexceeded", async function (file) {
            await this.removeAllFiles();
            this.addFile(file);
          });
        },
        headers: {
          Authorization: this.token,
        },
        acceptedFiles: "image/*",
      });

      var self = this;
      this.slipDropzone.on("success", function (file, res) {
        try {
          const file_name = res.datas.file_name;
          self.topup.receipt_url = `https://termgame-api.goldenbug.co/files/${file_name}`;
        } catch (error) {
          self.checkLogin();
        }
      });

      this.slipDropzone.on("error", (file, res) => {
        this.topup.receipt_url = "error";
        this.$swal({
          icon: "error",
          title: "ไม่สามารถอัพโหลดภาพได้",
          text: "ไฟล์ภาพต้องไม่เกิน 1mb",
          type: "warning",
        });
        this.removeFile(file);
      });
    }, 500);
    // console.log('this.banks: ', this.banks);
  },
  computed: {
    ...mapGetters([
      "getSetting",
      "banks",
      "getMemberProfile",
      "getSettingCMSAll",
      "getQrCode"
    ]),
    totalAmount() {
      return this.cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    },
    grandTotal() {
      return  this.totalAmount - this.discount;
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    ...mapActions(["fetchBanks",'getSettingCMS']),
    resetSlip() {
      try {
        this.slipDropzone.removeAllFiles(true);
        this.topup.receipt_url = "";
      } catch (error) {
        console.log("error: " + error);
      }
    },
    increaseQuantity(item) {
      item.quantity++;
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--;
      }
    },
    downloadQRCode() {
      // เพิ่มคลาสอนิเมชั่นเมื่อเริ่มการดาวน์โหลด
      this.animationClass = true;

      // ใช้ DOMParser เพื่อแปลง HTML string เป็น DOM node
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.qrCodeImage, 'image/svg+xml');
      const svgBlob = new Blob([new XMLSerializer().serializeToString(doc.documentElement)], { type: 'image/svg+xml' });
      this.downloadUrl = URL.createObjectURL(svgBlob);

      // คลิกลิงก์เพื่อดาวน์โหลด
      const link = this.$refs.downloadLink;
      link.href = this.downloadUrl;
      link.click();

      // รีเซ็ต download URL
      URL.revokeObjectURL(this.downloadUrl);

      // ลบคลาสอนิเมชั่นหลังจากการดาวน์โหลดเสร็จ
      setTimeout(() => {
        this.animationClass = false;
      }, 1000); // ใช้เวลาตามที่ต้องการให้อนิเมชั่นเสร็จสิ้น
    },
    selectPaymentMethod(method) {
      this.selectedPaymentMethod = method;
      // ทำการโหลดข้อมูลที่เกี่ยวข้องกับวิธีการชำระเงินที่เลือก
      if (method === 'bank') {
        // โหลดข้อมูลธนาคาร
      } else if (method === 'wallet') {
        // โหลดข้อมูลวอลเล็ต
      }
    },
    confirmPayment() {
      if (!this.selectedPaymentMethod) {
        // แจ้งเตือนให้เลือกวิธีการชำระเงิน
        return;
      }
      // ดำเนินการชำระเงินตามวิธีที่เลือก
      console.log("ดำเนินการชำระเงินด้วยวิธี:", this.selectedPaymentMethod);
    },
    copyToClipboard(bank_number, text = null) {
      try {
        const el = document.createElement("textarea");
        el.value = bank_number;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);

        const Toast = this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: text ? text : "คัดลอกเลขที่บัญชีแล้ว",
        });
      } catch (error) {}
    },
    copyBank(payment) {
      if (payment != "credit") {
        this.copyToClipboard(payment.account_no);
      }
    },
  }
};
</script>

<style scoped>
.image-item {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.quantity-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 38px;
}

.btn-quantity {
  width: 38px;
  height: 38px;
  background-color: #e00b60;
  border: 0;
  color: #495057;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.btn-quantity:hover {
  background-color: #e9ecef;
}

.quantity-value {
  width: 50px;
  height: 38px;
  text-align: center;
  border: 1px solid #ced4da;
  border-left: none;
  border-right: none;
  background-color: #fff;
  color: #495057;
  font-size: 16px;
  padding: 0;
  border-radius: 0;
}

.card-title {
  margin-bottom: 0;

}

.payment-method-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.payment-method-card:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.payment-method-card.selected {
  background-color: rgba(224, 11, 96, 0.5);
  border: 2px solid #e00b60;
}

.payment-method-card i {
  font-size: 24px;
  color: white;
  margin-bottom: 5px;
}

.payment-method-card span {
  display: block;
  color: white;
}

.check-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #e00b60;
}
.mobile-display {
  display: none;
}

/* ปรับแต่งสำหรับหน้าจอขนาดเล็ก */
@media (max-width: 767.98px) {
  .quantity-input {
    justify-content: flex-start;
    margin-top: 10px;
  }
  
  .card-title, .card-text {
    margin-bottom: 10px;
  }
  .mobile-display {
    display: block;
  }
  .desktop-display {
    display: none;
  }
}
</style>