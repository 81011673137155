import axios from 'axios';
// const API_URL = process.env.VUE_APP_API_URL;
const API_URL = "https://termgame-api.goldenbug.co/api";

export default {
  state() {
    return {
      banners: [],
      banner: null
    }
  },
  mutations: {
    setBanners(state, banners) {
      state.banners = banners;
    },
    setBanner(state, banner) {
      state.banner = banner;
    },
    addBanner(state, banner) {
      state.banners.push(banner);
    },
    updateBanner(state, updatedBanner) {
      const index = state.banners.findIndex(banner => banner._id === updatedBanner._id);
      if (index !== -1) {
        state.banners[index] = updatedBanner;
      }
    },
    removeBanner(state, bannerId) {
      state.banners = state.banners.filter(banner => banner._id !== bannerId);
    }
  },
  actions: {
    async fetchBanners({ commit }) {
      try {
        const response = await axios.post(`${API_URL}/banner/getList`);
        commit('setBanners', response.data.datas);
      } catch (error) {
        console.log('An error occurred while fetching banners: ', error);
      }
    },
    async fetchBannerById({ commit }, bannerId) {
      try {
        const response = await axios.post(`${API_URL}/banner/getList`, {
          game_id: bannerId
        });
        commit('setBanners', response.data.datas);
      } catch (error) {
        console.log('An error occurred while fetching the banner: ', error);
      }
    },
    async createBanner({ commit }, bannerData) {
      try {
        const response = await axios.post(`${API_URL}/banners`, bannerData);
        commit('addBanner', response.data);
      } catch (error) {
        console.log('An error occurred while creating the banner: ', error);
      }
    },
    async updateBanner({ commit }, bannerData) {
      try {
        const response = await axios.put(`${API_URL}/banners/${bannerData._id}`, bannerData);
        commit('updateBanner', response.data);
      } catch (error) {
        console.log('An error occurred while updating the banner: ', error);
      }
    },
    async deleteBanner({ commit }, bannerId) {
      try {
        await axios.delete(`${API_URL}/banners/${bannerId}`);
        commit('removeBanner', bannerId);
      } catch (error) {
        console.log('An error occurred while deleting the banner: ', error);
      }
    }
  },
  getters: {
    banners(state) {
      return state.banners;
    },
    homeBanner(state) {
      return state.banners.find(x => x.display_page === 0);
    },
    gameBanner(state) {
      return state.banners;
    },
    banner(state) {
      return state.banner;
    }
  }
}