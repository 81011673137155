<template>
  <div v-if="showAlert" class="alert" :class="alertClass">
    {{ alertMessage }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BackgroundProcessAlert",
  data() {
    return {
      showAlert: false,
      alertMessage: "",
      alertClass: "",
    };
  },
  computed: {
    ...mapGetters(["getBackgroundProcessStatus"]),
  },
  watch: {
    getBackgroundProcessStatus(newStatus) {
      if (newStatus === "success") {
        this.showSuccessAlert();
      } else if (newStatus === "error") {
        this.showErrorAlert();
      }
    },
  },
  methods: {
    showSuccessAlert() {
      this.alertMessage = "เติมเงินสำเร็จ กำลังเช็ค ID ของคุณ";
      this.alertClass = "alert-success";
      this.showAlertTemporarily();
    },
    showErrorAlert() {
      this.alertMessage = "เติมเงินไม่สำเร็จ กรุณาติดต่อแอดมิน";
      this.alertClass = "alert-error";
      this.showAlertTemporarily();
    },
    showAlertTemporarily() {
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    },
  },
};
</script>

<style scoped>
.alert {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  border-radius: 4px;
  z-index: 1000;
  border: 0;
  font-size: 18px;
}
.alert-success {
  background-color: #d4edda;
  color: #155724;
}
.alert-error {
  background-color: #f8d7da;
  color: #721c24;
}
@media (max-width: 500px) {
  .alert {
    left: 10px;
    top: 100px;
    right: 10px;
    bottom: unset;
    text-align: center;
  }
}
</style>
